<template>
  <form @submit.prevent="saveProduct">
    <loading v-if="!product" />

    <div v-else>
      <div v-if="isSubscription">
        <b-table
          :data="provisions"
          :mobile-cards="true"
          hoverable
          detail-key="_id"
          class="has-vertical-align-middle"
        >
          <template slot="empty">
            <no-results
              title="No provisions"
              :cta="{ label: 'Add provision property' }"
              message="This plan doesn't provision anything yet."
              @click="addProvison"
            />
          </template>

          <template slot-scope="props">
            <b-table-column field="_id" label="Key">
              <b-input
                v-model="props.row._id"
                :disabled="processing"
                placeholder="eg. planTaskCredits"
              />
            </b-table-column>

            <b-table-column
              field="amount"
              label="Amount"
              @keydown.up.exact.native.stop
              @keydown.down.exact.native.stop
            >
              <b-input
                ref="input"
                v-model.number="props.row.amount"
                type="number"
                :disabled="processing"
                min="0"
              />
            </b-table-column>

            <b-table-column field="increment" label="Increment" width="1">
              <b-switch v-model="props.row.increment" :disabled="processing" />
            </b-table-column>

            <b-table-column field="monthly" label="Monthly" width="1">
              <b-switch v-model="props.row.monthly" :disabled="processing" />
            </b-table-column>

            <b-table-column custom-key="delete" width="1">
              <button
                type="button"
                class="button is-danger"
                :disabled="processing"
                @click="removeProvision(props.row)"
              >
                <b-icon icon="trash" size="is-small" />
              </button>
            </b-table-column>
          </template>
        </b-table>
        <div v-if="provisions.length > 0" class="has-margin-top-100">
          <button class="button is-dark is-outlined" @click="addProvison">
            Add provision property
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { doc, setDoc } from "@firebase/firestore";
export default {
  name: "ProductProvisions",
  props: {
    productId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      fields: [
        "dateCreated",
        "description",
        "isActive",
        "name",
        "provision",
        "type"
      ],
      form: {
        name: "",
        description: "",
        isActive: true,
        type: "subscription",
        provision: {}
      },
      provisions: [],
      types: [
        {
          label: "Subscription",
          value: "subscription"
        },
        {
          label: "Task credit",
          value: "taskCredit"
        }
      ]
    };
  },
  computed: {
    product() {
      return this.$store.getters["products/product"](this.productId);
    },
    isValid() {
      if (this.$_.isEmpty(this.form.name)) return false;
      if (this.$_.isEmpty(this.form.description)) return false;
      if (this.$_.find(this.provisions, p => this.$_.isEmpty(p._id.trim())))
        return false;
      if (
        !this.$_.isEqual(
          this.provisions,
          this.$_.uniqBy(this.provisions, "_id")
        )
      )
        return false;
      return true;
    },
    isSubscription() {
      return this.form.type === "subscription";
    },
    hasChange() {
      return !this.$_.isEqual(this.form, this.$_.omit(this.product, ["_id"]));
    }
  },
  watch: {
    provisions: {
      handler() {
        this.setFormProvisions();
      },
      deep: true
    },
    processing(newVal) {
      this.$emit("processing", newVal);
    },
    isValid(newVal) {
      this.$emit("valid", newVal);
    },
    loading(newVal) {
      this.$emit("loading", newVal);
    },
    hasChange: {
      handler(newVal) {
        this.$emit("hasChange", newVal);
      },
      immediate: true
    },
    product: {
      handler(newVal) {
        if (newVal) {
          this.form = this.$_.pick(this.$_.cloneDeep(newVal), this.fields);

          if (this.form.provision) {
            this.provisions = Object.keys(this.form.provision).map(key => {
              return this.$_.merge({}, { _id: key }, this.form.provision[key]);
            });
          }
        }
      },
      immediate: true
    },
    "form.type"(newType) {
      if (newType === "taskCredit") {
        this.$delete(this.form, "provision");
      } else if (newType === "subscription") {
        this.$set(this.form, "provision", {});
        this.setFormProvisions();
      }
    }
  },
  created() {
    this.$store.dispatch("products/observeProduct", {
      productId: this.productId
    });
  },
  beforeDestroy() {
    this.$store.dispatch("products/unobserveProduct", {
      productId: this.productId
    });
  },
  methods: {
    removeProvision(provision) {
      const index = this.provisions.indexOf(provision);
      this.provisions.splice(index, 1);
    },
    addProvison() {
      this.provisions.push({
        _id: "",
        amount: 0,
        monthly: false,
        increment: false
      });
    },
    setFormProvisions() {
      this.form.provision = {};
      this.provisions.forEach(
        i =>
          i._id &&
          this.$_.set(this.form, `provision.${i._id}`, this.$_.omit(i, ["_id"]))
      );
    },
    saveProduct() {
      if (!this.isValid) return;

      this.processing = true;
      let form = this.form;
      let productRef = doc(this.$firestore, "products", this.productId);

      setDoc(productRef, form)
        .then(() => {
          this.processing = false;
          this.$emit("success");
          this.$toast.open({
            message: `Product saved`,
            type: "is-success"
          });
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: `Error saving product`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
